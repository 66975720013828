import styled from "styled-components";
import { motion } from "framer-motion";

export const About = styled(motion.section)`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 10rem;
  color: white;
  
  @media (max-width: 1300px) {
    display: block;
    padding: 2rem 2rem;
    text-align: center;
  }

  @media (max-width: 950px) {
    .arrowDownAnimated {
      display: none;
    }
  }

`;

export const AboutServices = styled(motion.section)`
  padding: "5rem 0rem";
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 5rem 0;

  h1 {
    font-size: 4rem;
    color: black;
    font-weight: lighter;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    text-align: center;

    .ImgServices {
      margin-top: 2rem;
      width: 50%
    }
  }

`

export const AboutProcess = styled(motion.section)`
  min-height: 0;
  padding-bottom: 5rem;
  padding-left: 10rem;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  .ImgProcess {
      object-fit: cover;
      border-radius: 1rem;
      height: 70vh;
      width: 100%;
      /* height: auto; */
    }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    text-align: center;

    h3 {
      max-width: 80%;
      text-align:justify;
      margin: 0 auto;
    }

    .ImgProcess {
      margin-top: 2rem;
      width: 50%;
      height: auto;
    }
  }

`
export const Description = styled.div`
  flex: 1;
  padding-right: 5rem;
  z-index: 2;

  .contactStagesBtn {
    margin-left: 25%;
  }

  &.DescriptionServices {
    padding-left: 10rem;
  }

  h2 {
    font-weight: lighter;
  }

  .line {
      height: 0.2rem;
      background: #fea654;
      margin-bottom: 3rem;
    }
  @media (max-width: 1300px) {
    padding: 0;
    &.DescriptionServices {
      padding-left: 0rem;

      & > div {
        max-width: 80%;
        margin: 2rem auto 0 auto;
      }
    }
    p {
      text-align: justify;
      max-width: 80%;
      margin: 0 auto;
    }
    .line {
      display: none;
    }
    button {
      margin: 2rem 0rem 5rem 0rem;
    }
  }

  @media (max-width: 800px) { 

    &.DescriptionServices {
      padding-left: 0rem;

      & > div {
        max-width: 100%;
        margin: 2rem auto 0 auto;
      }
    }

    h2, h1 {
      font-size: 2rem;
    }

    button {
      display: block;
      margin: 0 auto 2rem auto;
    }

    .contactStagesBtn {
    margin-left: auto;
  }
  }

  @media (max-width: 400px) { 
   
    button {
     margin-top: 0 ;
    }

  }

`;
export const Image = styled.div`
  z-index: 2;
  flex: 1;
  overflow: hidden;
  img {
    height: 80vh;
    object-fit: cover;
    border-radius: 50%;
    overflow: visible;
    width: 100%;
  }

  img.LandingAboutImg {
    display: block;
    margin: 0 auto;
    width:600px;
    height: 600px;
  }

  @media (max-width: 800px) { 
  img.LandingAboutImg {
    width:313px;
    height: 40vh;
  }
  }
`;



export const Hide = styled.div`
  overflow: hidden;
`;
