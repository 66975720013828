import React from "react";
//Import Icons
import home2 from "../img/zen-4925496_1920.jpg";
//Styles
import { About, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim, lineAnim } from "../animation";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";


const WhySection = () => {
  const [ element, controls ] = useScroll();
  return (
    <ReasonsSections
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >

      <Description >
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>Pourquoi consulter ? </motion.h2>
          </Hide>

          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
        </motion.div>

        <motion.div className="answerWhy">
          <div>
            <motion.p variants={fade} style={{ textAlign: "left", paddingBottom: "1rem" }}>
              <span>Amélioration du quotidien</span>
            </motion.p>
            <ul className="answerWhyFirst" >
              <li style={{ listStyle: 'none' }}> <p> Le stress, les émotions</p>   </li>
              <li style={{ listStyle: 'none' }}><p>La confiance en soi</p></li>
              <li style={{ listStyle: 'none' }}><p>L’estime de soi.</p>  </li>
              <li style={{ listStyle: 'none' }}><p>L’anxiété</p></li>
              <li style={{ listStyle: 'none' }}><p>Crise d’angoisse</p></li>
              <li style={{ listStyle: 'none' }}><p>Ruminations</p></li>
              <li style={{ listStyle: 'none' }}><p>Peurs (phobies)</p></li>
              <li style={{ listStyle: 'none' }}><p>Douleurs</p></li>
              <li style={{ listStyle: 'none' }}><p>Concentration</p></li>
              <li style={{ listStyle: 'none' }}><p>Mémoire</p></li>
              <li style={{ listStyle: 'none' }}><p>Troubles du sommeil</p></li>
            </ul>
          </div>

          <div>
            <p style={{ textAlign: "left", paddingBottom: "1rem" }}> <span>Préparation mentale</span> </p>
            <ul style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
              <li style={{ listStyle: 'none' }}> <p>Examen écrit/oral</p></li>
              <li style={{ listStyle: 'none' }}><p>Permis de conduire</p></li>
              <li style={{ listStyle: 'none' }}><p>Entretien/RDV</p> </li>
              <li style={{ listStyle: 'none' }}><p>Concours</p></li>
              <li style={{ listStyle: 'none' }}><p>Accouchement</p> </li>
              <li style={{ listStyle: 'none' }}><p>Parcours PMA</p></li>
              <li style={{ listStyle: 'none' }}><p>Prise de parole en public</p></li>
            </ul>
          </div>

          <div>
            <p style={{ textAlign: "left", paddingBottom: "1rem" }}> <span> Complément suivi médical</span></p>
            <ul style={{ display: 'grid', gridTemplateColumns: "1fr 1fr ", gap: "1rem" }}>
              <li style={{ listStyle: 'none' }}><p>Accompagnement cancer</p></li>
              <li style={{ listStyle: 'none' }}><p>Fibromyalgie</p></li>
              <li style={{ listStyle: 'none' }}><p>Douleurs post-opératoires</p> </li>
              <li style={{ listStyle: 'none' }}><p>Douleurs chroniques</p></li>
            </ul>
          </div>


          <div>
            <p style={{ textAlign: "left", paddingBottom: "1rem" }}> <span> Complément suivi thérapeutique</span></p>
            <ul style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
              <li style={{ listStyle: 'none' }}><p>Addiction</p></li>
              <li style={{ listStyle: 'none' }}><p>Dépression</p></li>
              <li style={{ listStyle: 'none' }}><p>Troubles alimentaires</p></li>
            </ul>
          </div>
        </motion.div>

      </Description>

    </ReasonsSections >
  );
};

const ReasonsSections = styled(About)`
  margin-bottom: 8rem;
  display: block;
  min-height: 0;
  padding-bottom: 2rem;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #826356;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 2rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 0rem 0rem;
    }
  }

  .answerFlex, .answerStages {
    display: flex;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    p {
      text-align: left;
    }
  }

  .answerStages {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .answerWhy {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 0rem;
    padding-bottom:0;

    p {
      padding: 0rem 0rem;
      text-align: left;
    }

    .answerWhyFirst {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr ;
      gap: 1rem;
    }
  }
  
    .answerWhy > div {
      padding-bottom: 3rem;
      width: 80%;
    }

  @media (max-width: 1300px) {
    margin-bottom: 0;
    
    .answerWhy {
      justify-items: center;

      & > div {
        p {
          max-width: 100%;
        }
      }
    }

    .answerWhy, .answerFlex {
      ul {
        grid-gap: 1rem;
      }

   
      .answerWhyFirst {
      grid-template-columns: 1fr 1fr 
    }
    }
  }
  @media (max-width: 950px) {
    .answerWhy {
      grid-template-columns: 1fr ;
      grid-template-rows: 1fr;
    }
  }

  @media (max-width: 800px) { 

    h2 {
      font-size: 2rem;
    }
  }

  @media (max-width: 520px) {
    .answerFlex {
      ul {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const Description = styled.div`
  flex: 1;
  padding-right: 5rem;
  z-index: 2;

  .contactStagesBtn {
    margin-left: 25%;
  }

  &.DescriptionServices {
    padding-left: 10rem;
  }

  h2 {
    font-weight: lighter;
  }

  .line {
      height: 0.2rem;
      background: #fea654;
      margin-bottom: 3rem;
    }
  @media (max-width: 1300px) {
    padding: 0;
    &.DescriptionServices {
      padding-left: 0rem;
    }
    p {
      text-align: justify;
      max-width: 80%;
      margin: 0 auto;
    }
    .line {
      display: none;
    }
    button {
      margin: 2rem 0rem 5rem 0rem;
    }
  }

  @media (max-width: 800px) { 

    h2 {
      font-size: 2rem;
    }

    button {
      display: block;
      margin: 0 auto 2rem auto;
    }

    .contactStagesBtn {
    margin-left: auto;
  }
  }

  @media (max-width: 400px) { 
   
    button {
     margin-top: 0 ;
    }

  }

`;
export default WhySection;
