import React from "react";
import home1 from "../img/woman-4607378_1920.jpg";
import { About, Description, Image, Hide, } from "../styles";
import { Link } from "react-router-dom";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim, lineAnim } from "../animation";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>"VIVRE L'INSTANT </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              PRESENT AU QUOTIDIEN"
            </motion.h2>
          </Hide>
          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
        </motion.div>
        <motion.p variants={fade} style={{ paddingBottom: "0.5rem" }}>
          <span>La Sophrologie </span> permet de vous accompagner dans votre évolution personnelle et dans votre épanouissement.
        </motion.p>
        <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>

          Une méthode <span>accessible à tous</span>, simple et efficace pour améliorer votre qualité de vie familiale, sociale et professionnelle.
        </motion.p>
        <motion.p variants={fade} style={{ padding: "0.5rem 0 3rem 0" }}>

          Vous apprendrez à lâcher prise, développer vos propres ressources, prendre confiance en vous, être plus performant, faire face aux difficultés, gérer votre stress, <span >retrouver un état de bien-être</span> pour aller vers une qualité de vie meilleure.
        </motion.p>
        <motion.button variants={fade}> <Link to="contact" className="contactBtn">Contact</Link> </motion.button>
      </Description>
      <Image style={{ overflow: "visible" }}>
        <motion.img className="LandingAboutImg" variants={photoAnim} src={home1} alt="guy with a camera" />
      </Image>
      <motion.p className="arrowDownAnimated" animate={{ y: [ 40, -10, 40 ] }} transition={{ ease: "easeOut", repeat: "Infinity", duration: 3 }} variants={fade} style={{ position: "absolute", bottom: "5%", left: "2%", fontSize: "5rem", color: "#fea654" }}>&darr;</motion.p>
    </About>
  );
};

//Styled Components



export default AboutSection;
