import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import logo from "../img/logo-2.png";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <StyledFooter>


      <ul>
        <li>
          <Link to="/legal">Mentions légales</Link>
        </li>

        <li>
          <p style={{ padding: "0" }}>&copy;
            Copyright 2022</p>
        </li>
      </ul>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  min-height: 9vh;
  display: flex;
  margin: auto;
  justify-content: flex-end;
  align-items: center;
  /* padding: 0rem 10rem; */
  background: #fff;
  /* background: linear-gradient(to right, #fea654, #fec); */
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 5px #2f2a2a;

  a {
    color: #000000;
    text-decoration: none;
    font-size: 1.8rem;
  }
  ul {
    display: flex;
    list-style: none;
  }

  li {
    padding-right: 10rem;
    position: relative;
  }
  @media (max-width: 1300px) {
    flex-direction: row;
    padding: 0rem 2rem;
    
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }

  @media (max-width: 750px) {
    padding-left: 0;
    padding-right: 0;

    ul {
      padding-left: 0;
    }
  }

  @media (max-width: 590px) {
    flex-direction: column;

    a {
      font-weight: initial;
    }

    .mobileHeight {
      bottom: -20%;
    }

    ul {
      padding: 0;
      padding-bottom: 0.8rem;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    ul li a {
      font-size: 1.4rem;
    }
  }
`;


export default Footer;
