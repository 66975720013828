import React from "react";
//Import Icons
import home2 from "../img/zen-4925496_1920.jpg";
//Styles
import { AboutServices, Description, Image, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim, lineAnim } from "../animation";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";


const ServicesSection = () => {
  const [ element, controls ] = useScroll();
  return (
    <AboutServices
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >

      <Description className="DescriptionServices" >
        <motion.div >
          <Hide>
            <motion.h1 variants={titleAnim}>LA SOPHROLOGIE </motion.h1>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>est une méthode de bien-être et de développement personnel</motion.h2>
          </Hide>
          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
        </motion.div>

        <motion.p variants={fade} style={{ paddingBottom: "0.5rem" }}>
          Créée par le neuropsychiatre Caycedo dans les années 1960, elle permet de vous accompagner dans votre évolution personnelle et dans votre épanouissement.
        </motion.p>
        <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
          La sophrologie est une méthode exclusivement <span>verbale et non tactile</span>. <br />
          Elle emploie un ensemble de techniques qui vont à la fois agir sur le corps et sur le mental. Elle combine des exercices qui travaillent sur la  <span >respiration</span>, la <span>décontraction musculaire</span> et <span>l'imagerie mentale</span> (ou visualisation).
        </motion.p>
        <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
          Toutes ces techniques permettent de retrouver un état de bien-être et d'activer tout son potentiel. La sophrologie permet d'acquérir une meilleure connaissance de soi et d'affronter les défis du quotidien avec sérénité.
        </motion.p>

      </Description>
      <Image className="ImgServices" style={{ flex: "1" }}>
        <motion.img variants={photoAnim} src={home2} style={{ objectFit: "contain", borderRadius: "1rem", height: "auto" }} alt="guy with a camera" />
      </Image>

    </AboutServices >
  );
};



export default ServicesSection;
