import React from "react";
import { FaMobileAlt, FaEnvelope, FaFacebookSquare, FaHouseUser, FaInstagram } from 'react-icons/fa';
//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  titleAnim,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim
} from "../animation";
import ScrollTop from "../components/ScrollTop";

import styled from "styled-components";

const ContactUs = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <Title>
        <Hide>
          <motion.h2 variants={titleAnim}>Coordonnées</motion.h2>
        </Hide>
        <Hide>
          <motion.div variants={lineAnimTarifs} className="line"></motion.div>
        </ Hide>
      </Title>
      <div>
        <Hide>
          <SocialTitle variants={titleAnim}>
            <h2 >Nathalie CARMONA EI</h2>
          </SocialTitle>
        </Hide>
        <ContactMethods>
          <div style={{ height: "30vh", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            <Hide>
              <Social variants={titleAnim}>
                <FaMobileAlt className="icon" /><p>06 71 89 93 38</p>
              </Social>
            </Hide>
            <Hide>
              <Social variants={titleAnim}>
                <FaEnvelope className="icon" /><a href="mailto:nathalie.carmona@gmail.com">Envoyer un Email</a>
              </Social>
            </Hide>
            <Hide>
              <Social variants={titleAnim}>
                <FaFacebookSquare className="icon" /><a href="https://www.facebook.com/nathalie.carmona.sophro" target="_blank">Facebook</a>
              </Social>
            </Hide>
            <Hide>
              <Social variants={titleAnim}>
                <FaInstagram className="icon" /><a href="https://www.instagram.com/nathalie.carmona.sophro/" target="_blank">Instagram</a>
              </Social>
            </Hide>
          </div>
          <ContactOffice>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
              <Hide>
                <Social variants={titleAnim}>
                  <p >Je vous reçois à mon <span style={{ color: "black" }}>cabinet</span> </p>
                </Social>
              </Hide>
              {/* <Hide>
                <SocialCabinet variants={titleAnim} >
                  <FaHouseUser className="icon-cabinet" /><p >Cabinet : </p>
                  <p> Life Ambassadair <br /> 34 Rue Emma Blanc <br /> Saint Jean de Védas</p>
                </SocialCabinet>
              </Hide> */}
              <Hide>
                <SocialCabinet variants={titleAnim} >
                  <FaHouseUser className="icon-cabinet" /><p >Cabinet </p>
                  <p> de Sophrologie & Bien-être <br />  Saint Jean de Védas</p>
                </SocialCabinet>
              </Hide>
              <Hide>
                {/* <a target="_blank" href="https://www.resalib.fr/praticien/98682-nathalie-carmona-sophrologue-saint-jean-de-vedas"><img style="box-shadow: 1px 3px 12px #555; border-radius:10px;" width="350" alt="Retrouvez Nathalie CARMONA sur Resalib : annuaire, référencement et prise de rendez-vous pour les Sophrologues" src="https://www.resalib.fr/app/images/generate/fbk_98682.png"></a> */}
                {/* <Image style={{ overflow: "visible", boxShadow: "1px 3px 12px #555", borderRadius: "10px", width: "350px", height: "150px" }}>
                  <motion.img className="LegalImgMediation" variants={photoAnim} src={"https://www.resalib.fr/app/images/generate/fbk_98682.png"} alt="lien association sophrologie" />

                </Image> */}
                <Image style={{ overflow: "visible" }}>
                  <a href="https://www.resalib.fr/praticien/98682-nathalie-carmona-sophrologue-saint-jean-de-vedas" target="_blank"><motion.img className="LegalImgMediation" variants={photoAnim} src={"https://www.resalib.fr/app/images/generate/fbk_98682.png"} alt="lien médiateur consommation" />
                  </a>
                </Image>
              </Hide>
            </div>
          </ContactOffice>
        </ContactMethods>
      </div>
      <ScrollTop />
    </ContactStyle >
  );
};

const ContactStyle = styled(motion.section)`
  padding: 5rem 10rem;
  color: #000;
  min-height: 90vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;

const ContactMethods = styled(motion.div)`
  display: flex;
  justify-Content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;

  .icon, .icon-cabinet {
    font-size: 3rem;
    margin-right: 2rem;
    color: #fea654;
  };

  .icon-cabinet {
    margin-top: 0.8rem
  }
`;

const ContactOffice = styled(motion.div)`
  display: flex;
  flex-direction: column; 
  align-items: flex-start;

`;

const Title = styled.div`
  margin-bottom: 4rem;
  color: #ccc;

   .line {
      height: 0.2rem;
      width: 30% ;
      background: #fea654;
      margin-bottom: 3rem;
    }
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }

  @media (max-width: 600px) {
    margin-bottom: 2rem;
    margin-top: 2rem;

    .line {
      margin-bottom: 0;
    }

    h2 {
      font-size: 3rem;
    }
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

const SocialTitle = styled(motion.div)`
  display: flex;
  align-items: center;
  h2 {
    font-size: 3rem;
    margin: 2rem 2rem 2rem 0;
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const Social = styled(motion.div)`
  display: flex;
  align-items: center;
  a, p {
    padding: 1.5rem 0;  
    text-decoration: none;
    color: #000;
    font-size: 1.4rem;
  }
`;

const SocialCabinet = styled(Social)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  p {
    margin-right: 0.5rem
  }
`;

const lineAnimTarifs = {
  hidden: { width: "0%" },
  show: {
    width: "35%",
    transition: { duration: 1 },
  },
};

export const Image = styled.div`
  z-index: 2;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  
  img {
    height: 30vh;
    object-fit: cover;
    /* box-shadow: 2px 5px 15px #555; */
    border-radius:10px;
      width:100%;
    /* width: 100%;
    height: 80vh;
    object-fit: cover; */
  }

    @media (max-width: 500px) {
      img {
        height: 20vh;
      }
    }
`;


export default ContactUs;
