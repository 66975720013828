import React from "react";
//Global Style
import GlobalStyle from "./components/GlobalStyle";
//Import Pages
import LandingPage from "./pages/LandingPage";
import ContactUs from "./pages/ContactUs";
import AboutMe from "./pages/AboutMe";
import Legal from "./pages/Legal";
import PriceInfo from "./pages/PriceInfo";
import Clients from "./pages/Clients";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
//Router
import { Switch, Route, useLocation } from "react-router-dom";
//Animation
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/yoursophrologue" exact>
            <AboutMe />
          </Route>
          <Route path="/public" exact>
            <Clients />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/legal">
            <Legal />
          </Route>
          <Route path="/priceinfo">
            <PriceInfo />
          </Route>
        </Switch>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
