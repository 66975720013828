import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import logo from "../img/logo-2.png";

const Nav = () => {
  const { pathname } = useLocation();
  return (
    <StyledNav>
      <Link className="nav__logo" style={{ position: 'relative' }} to="/">
        <Img src={logo} alt="Accueil" />
      </Link>
      <ul>
        <li>
          <Link to="/">Accueil</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/" ? "100%" : "0%" }}
            className="mobileHeight"
          />
        </li>
        <li>
          <Link to="/yoursophrologue">Qui suis je ?</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/yoursophrologue" ? "100%" : "0%" }}
            className="mobileHeight"
          />
        </li>
        <li>
          <Link to="/public">Pour qui ?</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/public" ? "100%" : "0%" }}
            className="mobileHeight"
          />
        </li>
        <li>
          <Link to="/priceinfo">Tarifs</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/priceinfo" ? "100%" : "0%" }}
            className="mobileHeight"
          />
        </li>
        <li>
          <Link to="/contact">Contact</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/contact" ? "100%" : "0%" }}
            className="mobileHeight"
          />
        </li>
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  min-height: 5vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #fea654, #fec);
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 5px #2f2a2a;

  a {
    color: #000000;
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: bold;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    list-style: none;
  }
  .nav__logo {
    font-family: "Lobster", cursive;
    font-weight: lighter;
    margin-left: 5rem;
  }

  .logoLine {
    bottom: 10%
  }
  li {
    position: relative;
  }
  @media (max-width: 1400px) {
    flex-direction: row;
    .nav__logo {
      display: inline-block;

      img {
        max-width: 5rem;
      }
    }

    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }

  @media (max-width: 1000px) {
  .nav__logo {
    margin-left: 0;
  }
  }

  @media (max-width: 750px) {
  .nav__logo {
    margin-left: 0;
  }

  a {
    font-size: 1.5rem;
  }
  }

  @media (max-width: 590px) {
    flex-direction: column;

    a {
      font-weight: initial;
    }

    .mobileHeight {
      bottom: -25%;
      height: 0.2rem;
    }

    ul {
      padding: 0;
      padding-bottom: 0.8rem;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    ul li a {
      font-size: 1.2rem;
    }

    .nav__logo {
      img {
        max-width: 4rem;
      }
    }
  }
`;

const Img = styled.img`
max-width: 5rem;
`

const Line = styled(motion.div)`
  height: 0.3rem;
  background: black;
  width: 0%;
  position: absolute;
  bottom: -80%;
  /* left: 60%; */
  @media (max-width: 1300px) {
    left: 0%;
  }
`;

export default Nav;
