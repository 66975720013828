import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnim, photoAnim, fade } from "../animation";
import { About, Description, Image } from "../styles";
import home1 from "../img/logo-mediation.png";
// import home2 from "../img/LOGO-EXPERT.png";
import home3 from "../img/logo-chambre-syndicale.png";
// import { Link } from "react-router-dom";

import styled from "styled-components";
import ScrollTop from "../components/ScrollTop";


const PriceInfo = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >

      <Title>
        <Hide>
          <motion.h2 variants={titleAnim} style={{ fontWeight: "bold" }}>Mentions Légales</motion.h2>
        </Hide>
        <Hide>
          <motion.div variants={lineAnimTarifs} className="line"></motion.div>
        </ Hide>
      </Title>
      <TarifGroup >
        <div style={{ flex: "1 1 25%" }}>
          <Hide>
            <motion.p variants={titleAnim}>Site hébergé par: Netlify, Inc <br />
              Adresse: 44 Montgomery Street, Suite 300, San Francisco, California 94104 (USA)<br />
              Email de contact: support@netlify.com</motion.p>
          </Hide>
          <Hide>
            <motion.p variants={titleAnim}>Propriétaire du site : Nathalie Carmona <br />
              Création du site : Vicente Digital</motion.p>
          </Hide>
        </div>
        <div style={{ flex: "1 1 25%" }}>
          <Hide>
            <motion.p variants={titleAnim}> Autorité ayant délivré l'autorisation d’exercer : Sup de Sophro  <br />
              Adresse: 8 Rue Louis Blanc, 95 320 SAINT LEU LA FORET, France <br />
              Email de contact : contact@supdesophro.fr    </motion.p>
          </Hide>
          <Hide>
            <motion.p variants={titleAnim} >Code de déontologie de la Sophrologie : <a style={{ fontSize: "1.4rem", textDecoration: "none" }} href="./code-deontologie.pdf" download="code_de_deontologie"><span>Télécharger le pdf</span></a></motion.p>
          </Hide>
        </div>
      </TarifGroup>


      <TarifGroup style={{ marginTop: "5rem" }}>
        <div style={{ flex: "1 1 25%" }}>
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim} >Médiation Professionnelle</motion.h2>
            </Hide>
          </motion.div>
          <Hide>
            <motion.p variants={titleAnim}>
              Partenaire de la Médiation Professionnelle <br />
              LITIGE – MEDIATION DE LA CONSOMMATION <br />
              En cas de litige entre le Client et l’entreprise, ceux-ci s’efforceront de le résoudre à l’amiable (le Client adressera une réclamation écrite auprès du Service Relations Clientèle du Constructeur ou celui du Vendeur).
            </motion.p>
          </Hide>
          <Hide>
            <motion.p variants={titleAnim}>

              A défaut d’accord amiable ou en l’absence de réponse du professionnel dans un délai raisonnable d’un (1) mois, le Client consommateur au sens de l’article L.133-4 du code de la consommation a la possibilité de saisir gratuitement, si un désaccord subsiste, le médiateur compétent inscrit sur la liste des médiateurs établie par la Commission d’évaluation et de contrôle de la médiation de la consommation en application de l’article L.615-1 du code de la consommation, à savoir : <br />
              La Société Médiation Professionnelle
              www.mediateur-consommation-smp.fr <br />
              24 rue Albert de Mun - 33000 Bordeaux</motion.p>
          </Hide>
          <Hide>
            <Image style={{ overflow: "visible" }}>
              <a href="https://www.mediateur-consommation-smp.fr/" target="_blank"><motion.img className="LegalImgMediation" variants={photoAnim} src={home1} alt="guy with a camera" />
              </a>
            </Image>
          </Hide>
        </div>

        <div style={{ flex: "1 1 25%" }}>
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim}> Sophrologues Indépendants</motion.h2>
            </Hide>
          </motion.div>
          <Hide>
            <motion.p variants={titleAnim}>Membre de la Chambre Syndicale de la Sophrologie.</motion.p>
            <div className="sophroLogos-container">
              <Image style={{ overflow: "visible" }}>
                <motion.img className="LegalImgMediation" variants={photoAnim} src={home3} alt="lien vers chambre syndical de la sophrologie" />

              </Image>
              {/* <Image style={{ overflow: "visible" }}>
                <a href="https://www.syndicat-sophrologues-independant.fr/" target="_blank"><motion.img className="LegalImgMediation" variants={photoAnim} src={home2} alt="guy with a camera" />
                </a>
              </Image> */}
            </div>
          </Hide>
        </div>


      </TarifGroup >

      <ScrollTop />

    </ContactStyle >
  );
};

const ContactStyle = styled(motion.section)`
  padding: 5rem 10rem;
  background: #fff;
  overflow: hidden;
  color: #000;
  
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;


const TarifGroup = styled(motion.div)`

  display: flex;
  justify-Content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 4rem;

  .LegalImgMediation {
    border-radius: 0;
    height: auto;
    width: auto;
    margin-top: 3rem;
  }

  .icon, .icon-cabinet {
    font-size: 3rem;
    margin-right: 2rem
  };

  .icon-cabinet {
    margin-top: 0.2rem
  }

  .sophroLogos-container {
    display: flex;

    img {
      max-width: 10rem;
    }
  } 

  h2 {
    color: #000;
    font-size: 3rem;
    font-weight: lighter;
  }

  p {
    padding-top: 3rem ;
    padding-bottom: 0;
    color: #000;
    font-size: 1.4rem;
    line-height: 150%;
  }

  .pt7 {
    padding-top: 7.1rem
  }

  .pt5 {
    padding-top: 5rem
  }

  @media (max-width: 1250px) {
    p {
      padding-bottom: 0;
    }
  }

  @media (max-width: 950px) {
    .arrowDownAnimated {
      display: none;
    }
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 2rem;
    }
  }
`;

const ContactOffice = styled(motion.div)`
  display: flex;
  flex-direction: column; 
  align-items: flex-start
`;

const Title = styled.div`
  margin-bottom: 4rem;

  .line {
      height: 0.2rem;
      width: 30% ;
      background: #fea654;
      margin-bottom: 3rem;
    }
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }

  @media (max-width: 600px) {
    margin-bottom: 2rem;
    margin-top: 2rem;

    h2 {
      font-size: 3rem;
    }
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

const lineAnimTarifs = {
  hidden: { width: "0%" },
  show: {
    width: "30%",
    transition: { duration: 1 },
  },
};


export default PriceInfo;
