import React from "react";
//Import Icons
import home2 from "../img/zen-4925496_1920.jpg";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim, lineAnim } from "../animation";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";
import { Link } from "react-router-dom";



const WhoAndWhySection = () => {
  const [ element, controls ] = useScroll();
  return (
    <WhoAndWhy
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >

      <Description className="DescriptionWhoAndWhy" >
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>
              Etymologie
            </motion.h2>
          </Hide>
          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
        </motion.div>
        <motion.p variants={fade} style={{ paddingBottom: "0.5rem" }}>
          Le terme Sophrologie vient du grec ancien et se traduit par: <span style={{ color: "black", display: "inline-block" }}>Sos Phren Logos</span >
        </motion.p>
        <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
          <span style={{ display: 'inline-block' }}>Sos</span> : signifie l’harmonie, la sérénité <br />
          <span style={{ display: 'inline-block' }}>Phren</span> : signifie la conscience, l’esprit <br />
          <span style={{ display: 'inline-block' }}>Logos</span> : définit la science l’étude. <br /> <br />
          Cela peut alors se traduire par <span style={{ color: "black", display: "inline-block" }}>«l’Harmonisation du corps et de l’esprit»</span >
        </motion.p>

      </Description>

      <Description className="DescriptionWhoAndWhyRight">
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>
              Pour qui ?
            </motion.h2>
          </Hide>
          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
        </motion.div>
        <div className="who-container" >
          <ul className="who-container__list">
            <li style={{ listStyle: 'none' }}><p>Enfants dès 5 ans</p></li>
            <li style={{ listStyle: 'none' }}><p>Adolescents</p></li>
            <li style={{ listStyle: 'none' }}><p>Adultes </p></li>
            <li style={{ listStyle: 'none' }}><p>Séniors</p></li>
            <li style={{ listStyle: 'none' }}><p>Femmes enceintes</p></li>
            <li style={{ listStyle: 'none' }}><p>Sportifs</p></li>
            <li style={{ listStyle: 'none' }}><p>Entreprises et organismes</p></li>
          </ul>
          <motion.button variants={fade} style={{ height: "fit-content", alignSelf: "center" }}> <Link to="public" className="contactBtn">Public</Link> </motion.button>
        </div>

      </Description>
    </WhoAndWhy >
  );
};

export const WhoAndWhy = styled(motion.section)`
padding: "5rem 0rem";
  /* min-height: 90vh; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  padding: 5rem 0;

  h1 {
    font-size: 4rem;
    color: black;
    font-weight: lighter;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    text-align: center;
    align-items: center;
    gap: 4rem;

    .ImgServices {
      margin-top: 2rem;
      width: 50%
    }
  }

`
export const Description = styled.div`
  flex: 1;
  padding-right: 5rem;
  z-index: 2;

  .contactStagesBtn {
    margin-left: 25%;
  }

  &.DescriptionWhoAndWhy {
    padding-left: 10rem;
    max-width: 80%;
    margin: 2rem auto 0 auto;
  }

  &.DescriptionWhoAndWhyRight {
    max-width: 80%;
    margin: 2rem auto 0 auto;
  }

  h2 {
    font-weight: lighter;
  }

  .line {
      height: 0.2rem;
      background: #fea654;
      margin-bottom: 3rem;
    }

  .who-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .who-container__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    align-items: center;
  }

  @media (max-width: 1300px) {
    padding: 0;
    &.DescriptionWhoAndWhy {
      padding-left: 0rem;
    }
    p {
      text-align: justify;
      /* max-width: 80%; */
      margin: 0 auto;
    }
    .line {
      display: none;
    }
    button {
      margin: 2rem 0rem 5rem 0rem;
    }
  }

  @media (max-width: 800px) { 

    h2 {
      font-size: 2rem;
    }

    button {
      display: block;
      margin: 0 auto 2rem auto;
    }

    .contactStagesBtn {
    margin-left: auto;
  }
  }

  @media (max-width: 640px) { 

    &.DescriptionWhoAndWhyRight h2 {
        margin-bottom: 2rem;
    }
   
    .who-container__list li p {
      padding: 1rem 0 0 0;
    }

    .who-container__list + button  {
      margin-top: 4rem;
    }

  }
  @media (max-width: 400px) { 
   
    .who-container__list {
      flex-direction: column;
      gap: 0;
    }

    button {
     margin-top: 0 ;
    }

  }
`


export const Hide = styled.div`
  overflow: hidden;
`;


export default WhoAndWhySection;
