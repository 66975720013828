import React from "react";
//Page Components
import AboutSection from "../components/AboutSection";
import ServicesSection from "../components/ServicesSection";
import Process from "../components/Process";
import WhoAndWhySection from "../components/WhoAndWhySection";
import WhySection from "../components/WhySection";
//Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import ScrollTop from "../components/ScrollTop";

const AboutUs = () => {
  return (
    <motion.div
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      style={{
        overflow: "hidden"
      }}
    >
      <AboutSection />
      <ServicesSection />
      <WhoAndWhySection />
      <WhySection />
      <Process />
      <ScrollTop />
    </motion.div>
  );
};

export default AboutUs;
