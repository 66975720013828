import React from "react";
//Import Icons
import home2 from "../img/Nénuphare.jpg";
//Styles
import { AboutProcess, Description, Image, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim, lineAnim } from "../animation";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";


const Process = () => {
  const [ element, controls ] = useScroll();
  return (
    <AboutProcess
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >

      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>COMMENT </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>se déroule une séance </motion.h2>
          </Hide>
          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
        </motion.div>

        <motion.h3 variants={fade} style={{ paddingBottom: "0.5rem", fontWeight: "bold", textDecoration: "underline" }}>
          1er ENTRETIEN
        </motion.h3>
        <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
          Il permet de vous rencontrer de préciser votre <span>demande</span>, <span>vos besoins</span> et de définir <span>votre objectif</span>, et ainsi de s’assurer que la sophrologie peut y répondre
        </motion.p>
        <motion.h3 variants={fade} style={{ padding: "0.5rem 0", fontWeight: "bold", textDecoration: "underline" }}>
          LA SEANCE
        </motion.h3>

        <motion.ul variants={fade} style={{ padding: "0.5rem 0" }}>
          <ProcessList> <p><span>1.</span> Un temps d’accueil, d’écoute et d’échange</p></ProcessList>
          <ProcessList><p><span>2.</span> Un temps de pratique d’exercices de relaxation dynamiques qui sont des mouvements doux associés à la respiration contrôlée.</p></ProcessList>
          <ProcessList><p><span>3.</span> Un temps de relaxation statique dirigée qui s’attache au relâchement mental et à la projection d’images positives de ses capacités.</p></ProcessList>
          <ProcessList><p><span>4.</span> Un temps d’échange, qui sert à exprimer les ressentis.</p></ProcessList>
          <motion.p variants={fade} style={{ padding: "0.5rem 0", color: "black" }}>
            <strong>La Sophrologie ne se substitue pas à un traitement médical ou à un avis médical </strong>
          </motion.p>
        </motion.ul>

      </Description>
      <Image style={{ flex: "1" }}>
        <motion.img variants={photoAnim} className="ImgProcess" src={home2} alt="guy with a camera" />
      </Image>

    </AboutProcess>
  );
};

const ProcessList = styled.li`
 list-style: none;

 p {
   padding: 0.5rem 0;
 }
`


export default Process;
