import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnim, fade, photoAnim } from "../animation";
import { Image } from "../styles";

import home1 from "../img/logo-mediation.png";
// import home2 from "../img/LOGO-EXPERT.png";
import home3 from "../img/logo-chambre-syndicale.png";

import styled from "styled-components";
import ScrollTop from "../components/ScrollTop";


const PriceInfo = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >

      <Title>
        <Hide>
          <motion.h2 variants={titleAnim} style={{ fontWeight: "bold" }}>Tarifs</motion.h2>
        </Hide>
        <Hide>
          <motion.div variants={lineAnimTarifs} className="line"></motion.div>
        </ Hide>
      </Title>
      <TarifGroup style={{ minHeight: "40vh", gap: "0" }}>
        <div className="tarif-list" >
          <Hide>
            <motion.h2 variants={titleAnim}>Adultes</motion.h2>
            <motion.p variants={titleAnim} style={{ paddingBottom: "0" }}>Séance individuelle avec établissement d'un protocole sur mesure : <br />
              1ère séance (90 min): <span>60 € </span><br />
              Séance de suivi (1H00): <span>50 €</span></motion.p>
          </Hide>
          <Hide>
            <motion.p variants={titleAnim} style={{ paddingBottom: "5rem" }}>Séance en groupe (6 pers. mini) <br />
              <span>15 €</span> la séance /personne (1H00)</motion.p>
          </Hide>
        </div>
        <div className="tarif-list">
          <Hide>
            <motion.h2 variants={titleAnim}>Enfants / Adolescents</motion.h2>
            <motion.p variants={titleAnim} style={{ paddingBottom: "0" }}>Séance individuelle avec établissement d'un protocole sur mesure : <br />
              1ère séance (1H00): <span>50 €</span> <br />
              Séance de suivi (45 min): <span>45 €</span></motion.p>
          </Hide>
          <Hide>
            <motion.p variants={titleAnim} style={{ paddingBottom: "5rem" }}>Séance en groupe (6 pers. mini) <br />
              <span>12 €</span> la séance /personne (45 min)</motion.p>
          </Hide>
        </div>
        <div className="tarif-list">
          <Hide>
            <motion.h2 variants={titleAnim}>Atelier de sophrologie</motion.h2>
            <motion.p variants={titleAnim} style={{ display: "inline-block" }}> <span> 28 €</span> (2H00)</motion.p>
            <motion.button variants={fade} style={{ marginLeft: "2rem" }}> <a href="https://www.facebook.com/nathalie.carmona.sophro" target="_blank" className="contactBtn" style={{ fontSize: "1.4rem" }}>Voir les stages</a> </motion.button>
          </Hide>
        </div>
        <div className="tarif-list">
          <Hide>
            <motion.h2 variants={titleAnim}> Séance en Entreprise ou Organisme </motion.h2>
            <motion.p variants={titleAnim}> Tarif à déterminer en fonction de la demande, <br />n’hésitez pas à me contacter pour établir un devis.</motion.p>
          </Hide>
        </div>
        <motion.p className="arrowDownAnimated" animate={{ y: [ 40, -10, 40 ] }} transition={{ ease: "easeOut", repeat: "Infinity", duration: 3 }} style={{ position: "absolute", bottom: "5%", left: "2%", fontSize: "5rem", color: "#fea654", paddingBottom: "3rem" }}>&darr;</motion.p>
      </TarifGroup>


      <TarifGroup style={{ marginTop: "5rem" }}>
        <div style={{ flex: "1 1 25%" }}>
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim} >Séances individuelles</motion.h2>
            </Hide>
          </motion.div>
          <Hide>
            <motion.p variants={titleAnim}>La Sophrologie est avant tout une <span>rencontre avec soi</span>. Avec la Sophrologie vous allez réapprendre à vous écouter, à entendre les messages de votre corps pour lui apporter une réponse adaptée et efficace, à ré-apprivoiser vos émotions pour redevenir l’acteur de votre vie.</motion.p>
          </Hide>
        </div>

        <div style={{ flex: "1 1 25%" }}>
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim}>Séances en groupes</motion.h2>
            </Hide>
          </motion.div>
          <Hide>
            <motion.p variants={titleAnim}> La Sophrologie se prête parfaitement à une pratique de groupe. La dynamique, les échanges, le partage du vécu des exercices sont autant de moyens d’enrichir et d’accélérer son propre cheminement.</motion.p>
          </Hide>
        </div>

        <div style={{ flex: "1 1 25%" }}>
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim}>Ateliers Sophrologie</motion.h2>
            </Hide>
          </motion.div>
          <Hide>
            <motion.p variants={titleAnim}> Les ateliers Sophro sont des ateliers thématiques qui se pratiquent en petit groupe, ils vous permettent de vous sensibiliser aux bienfaits de la sophrologie, tout en favorisant l’écoute et l’échange.</motion.p>
          </Hide>
        </div>
      </TarifGroup >

      <div>
        <Hide>
          <motion.p variants={fade} style={{ padding: "1.5rem 0 1.5rem 0" }}>

            <span>Mutuelle</span> : Certaines mutuelles prennent en charge les consultations, n'hésitez pas à vous renseigner auprès de la votre.
          </motion.p>
          <motion.p variants={fade} style={{ padding: "1.5rem 0 0 0" }}>

            <span>Mode de Paiement</span> : Espèces <span>/</span> Chèques <span>/</span> Ne prend pas de paiement par CB <br /> <br /> <strong>
              Nota: Toute séance non annulée 24H à l'avance vous sera facturée
            </strong>
          </motion.p>
        </Hide>
      </div>


      <Hide className="tarif-logos">
        <Image style={{ overflow: "visible" }}>
          <a href="https://www.mediateur-consommation-smp.fr/" target="_blank"><motion.img className="LegalImgMediation" variants={photoAnim} src={home1} alt="lien médiateur consommation" />
          </a>
        </Image>
        <Image style={{ overflow: "visible" }}>
          <motion.img className="LegalImgMediation" variants={photoAnim} src={home3} alt="lien association sophrologie" />

        </Image>
        {/* <Image style={{ overflow: "visible" }}>
          <a href="https://www.syndicat-sophrologues-independant.fr/" target="_blank"><motion.img className="LegalImgMediation" variants={photoAnim} src={home2} alt="lien association sophrologie" />
          </a>
        </Image> */}
      </Hide>


      <ScrollTop />
    </ContactStyle >
  );
};

const ContactStyle = styled(motion.section)`
  padding: 5rem 10rem;
  background: #fff;
  overflow: hidden;
  color: #000;
  
  .LegalImgMediation {
    border-radius: 0;
    height: auto;
    width: auto;
    margin: 0 auto;
    display: block;
    margin-top: 3rem;

    max-width: 20rem;
    max-height: 8rem;
  }

  .tarif-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center
  }

  @media (max-width:850px) {
    padding: 2.5rem 5rem;
  }

  @media (max-width:400px) {
    padding: 1rem 2rem;
  }

`;


const TarifGroup = styled(motion.div)`

  display: flex;
  justify-Content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 4rem;

  .tarif-list {
    width: 100%;
    max-width: 50%
  }

  
  .icon, .icon-cabinet {
    font-size: 3rem;
    margin-right: 2rem
  };
  
  .icon-cabinet {
    margin-top: 0.2rem
  }
  
  h2 {
    color: #000;
    font-size: 3rem;
    font-weight: lighter;
  }
  
  p {
    padding: 3rem 0rem;
    color: #000;
    font-size: 1.4rem;
    line-height: 150%;
  }
  
  .pt7 {
    padding-top: 7.1rem
  }
  
  .pt5 {
    padding-top: 5rem
  }
  
  @media (max-width: 950px) {
    .arrowDownAnimated {
      display: none;
    }
  }
  
  @media (max-width: 850px) {
    h2 {
      font-size: 2rem;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;

    div:nth-child(3) {
      margin-bottom: 3rem;
    }

    .tarif-list {
      max-width: 100%;
    }
  }
`;

const ContactOffice = styled(motion.div)`
  display: flex;
  flex-direction: column; 
  align-items: flex-start
`;

const Title = styled.div`
  margin-bottom: 4rem;

  .line {
      height: 0.2rem;
      width: 30% ;
      background: #fea654;
      margin-bottom: 3rem;
    }
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }

  @media (max-width: 600px) {
    margin-bottom: 2rem;
    margin-top: 2rem;

    h2 {
      font-size: 3rem;
    }
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

const lineAnimTarifs = {
  hidden: { width: "0%" },
  show: {
    width: "30%",
    transition: { duration: 1 },
  },
};


export default PriceInfo;
