import React from "react";
import styled from "styled-components";

//Images
// import enfants from "../img/enfants.jpg";
import enfants from "../img/PHOTO_ENFANTS.jpg";
import adulte from "../img/FILLE ET SOLEIL.jpg";
import entreprise from "../img/ENTREPRISE.jpg";

//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  titleAnim,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const AboutMe = () => {
  const [ element, controls ] = useScroll();
  const [ element2, controls2 ] = useScroll();
  return (
    <Work
      style={{
        background: "#fff"
      }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <About className="AboutAdult" >
        <Description className="AboutAdultDescription">
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim} >ADULTES ET  SENIORS</motion.h2>
            </Hide>
            <Hide>
              <motion.div variants={lineAnim} className="line"></motion.div>
            </ Hide>
          </motion.div>
          <div className="public-description__center">
            <Hide>
              <motion.p variants={titleAnim} className="public-description__heading">
                À tout moment dans la vie, nous pouvons être débordés par les événements, la sophrologie est là pour <span>vous aider</span> à :
              </motion.p>
            </Hide>
            <Formation variants={fade}>
              <FormationListing >
                <p><span>S</span>e reconstruire après une dépression, un burn out</p>
              </FormationListing>
              <FormationListing>
                <p><span>R</span>etrouver la confiance en soi, l’estime de soi</p>
              </FormationListing>
              <FormationListing>
                <p><span>O</span>ptimiser son efficacité personnelle</p>
              </FormationListing>
              <FormationListing>
                <p><span>G</span>érer le stress et les émotions</p>
              </FormationListing>
              <FormationListing>
                <p><span>R</span>ebondir après un échec</p>
              </FormationListing>
              <FormationListing>
                <p><span>A</span>ffronter les aléas de la vie</p>
              </FormationListing>
              <FormationListing>
                <p><span>R</span>etrouver un sommeil réparateur</p>
              </FormationListing>
              <FormationListing>
                <p><span>M</span>ieux gérer la douleur</p>
              </FormationListing>
              <FormationListing>
                <p><span>S</span>e préparer à une opération chirurgicale</p>
              </FormationListing>
              <FormationListing>
                <p><span>P</span>réparer un examen, un entretien</p>
              </FormationListing>
              <FormationListing>
                <p><span>E</span>ntretenir la mémoire, la concentration</p>
              </FormationListing>
              <FormationListing>
                <p><span>C</span>ombattre les troubles alimentaires</p>
              </FormationListing>
              <FormationListing>
                <p><span>V</span>aincre les addictions</p>
              </FormationListing>
            </Formation>
          </div>
        </Description>
        <Image>
          <motion.img variants={photoAnim} src={adulte} alt="photo of nathalie carmona" />
        </Image>
        <motion.p className="arrowDownAnimated" animate={{ y: [ 40, -10, 40 ] }} transition={{ ease: "easeOut", repeat: "Infinity", duration: 3 }} variants={fade} style={{ position: "absolute", bottom: "5%", left: "2%", fontSize: "5rem", color: "#fea654" }}>&darr;</motion.p>
      </About>

      <About className="AboutChild" >
        <Image >
          <motion.img className="imageChild" variants={photoAnim} src={enfants} alt="photo of nathalie carmona" />
        </Image>
        <Description className="AboutChildDescription ">
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim} > ENFANTS ET ADOLESCENTS</motion.h2>
            </Hide>
            <Hide>
              <motion.div variants={lineAnim} className="line"></motion.div>
            </ Hide>
          </motion.div>
          <div className="public-children-description__center">

            <Hide>
              <motion.p variants={titleAnim} style={{ padding: "2rem 0rem" }}>
                Les symptômes du stress chez les enfants et les adolescents sont parfois plus difficiles à détecter que chez l’adulte.
              </motion.p>
            </Hide>
            <Hide>
              <motion.p variants={titleAnim} style={{ padding: "0rem 0rem 1rem 0" }}>
                A partir de 6 ans, la sophrologie est un outil formidable, à la fois <span>ludique et relaxant</span> pour aider les enfants et adolescents qui :
              </motion.p>
            </Hide>
            <Formation variants={fade}>
              <FormationListing >
                <p><span>S</span>ouffrent de stress (peur de l’école et de l’échec)</p>
              </FormationListing>
              <FormationListing>
                <p><span>D</span>orment mal (endormissement ou réveils nocturnes)</p>

              </FormationListing>
              <FormationListing>
                <p><span>M</span>anquent de confiance en eux</p>
              </FormationListing>
              <FormationListing>
                <p><span>M</span>anquent de concentration</p>
              </FormationListing>
              <FormationListing>
                <p><span>S</span>ont trop actifs ou actives.</p>
              </FormationListing>
              <FormationListing>
                <p><span>E</span>xpriment de la colère ou de l’agressivité</p>
              </FormationListing>
              <FormationListing>
                <p><span>F</span>ont des blocages face aux examens</p>
              </FormationListing>
              <FormationListing>
                <p><span>S</span>e préparent pour le passage des examens</p>
              </FormationListing>
              <FormationListing>
                <p><span>A</span>ngoissent, ont du mal à gérer leurs émotions</p>

              </FormationListing>
              <FormationListing>
                <p><span>V</span>ivent des changements douloureux</p>

              </FormationListing>
              <FormationListing>
                <p><span>P</span>résentent des troubles alimentaires (prise de poids)</p>
              </FormationListing>
              <FormationListing>
                <p><span>D</span>éveloppent des douleurs psychosomatiques (maux de ventre, de maux de têtes…)</p>
              </FormationListing>
            </Formation>
          </div>
        </Description>
      </About>

      <About className="AboutEnterprise" >
        <Description className="AboutEnterpriseDescription">
          <motion.div>
            <Hide>
              <motion.h2 variants={titleAnim} >ENTREPRISES</motion.h2>
            </Hide>
            <Hide>
              <motion.div variants={lineAnim} className="line"></motion.div>
            </ Hide>
          </motion.div>
          <Hide>
            <motion.p variants={titleAnim} style={{ padding: "2rem 0rem" }}>
              Le taux d’<span>absentéisme</span> en France <span>reste élevé</span>, ce qui révèle un profond malaise dans le monde du travail. En plus du stress, certaines personnes peuvent développer des angoisses et de l’anxiété.
            </motion.p>
          </Hide>
          <Hide>
            <motion.p variants={titleAnim} style={{ padding: "0rem 0rem 1rem 0" }}>
              Pour garantir une bonne cohésion sociale dans l’entreprise, il est important de préserver la santé physique et mentale de son personnel
            </motion.p>
          </Hide>

          <div className="public-description__center">

            <motion.div>
              <Hide>
                <motion.p variants={titleAnim} style={{ padding: "2rem 0rem 1rem 0" }}>
                  La <span>SOPHROLOGIE</span>, apporte des <span>solutions efficaces</span> pour :
                </motion.p>
              </Hide>
            </motion.div>
            <Formation variants={fade}>
              <FormationListing >
                <p><span>G</span>érer le stress et les émotions </p>
              </FormationListing>
              <FormationListing>
                <p><span>R</span>enforcer la confiance en soi.</p>
              </FormationListing>
              <FormationListing>
                <p><span>G</span>érer la fatigue</p>
              </FormationListing>
              <FormationListing>
                <p><span>G</span>érer les conflits</p>
              </FormationListing>
              <FormationListing>
                <p><span>A</span>méliorer l’esprit d’équipe</p>
              </FormationListing>
              <FormationListing>
                <p><span>G</span>érer le temps</p>
              </FormationListing>
              <FormationListing>
                <p><span>A</span>méliorer la mémoire, et la concentration</p>
              </FormationListing>
              <FormationListing>
                <p><span>A</span>méliorer la prise de parole en public</p>
              </FormationListing>
              <FormationListing>
                <p><span>D</span>ésamorcer les conflits</p>
              </FormationListing>
            </Formation>
          </div>



        </Description>
        <Image style={{ paddingTop: "1rem" }}>
          <motion.img variants={photoAnim} src={entreprise} style={{ height: "auto", }} alt="photo of nathalie carmona" />
        </Image>
      </About>

      <ScrollTop />
    </Work>
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 0rem;
  padding-bottom: 0;

  h2 {
    padding: 1rem 0rem;
     text-align:left;
    }

  p{
        padding: 3rem 0rem;
        color: #000;
        font-size: 1.4rem;
        line-height: 150%;
    }

    @media (max-width: 1300px) {
    padding: 2rem 2rem;
    h2 {
    text-align:center;
  }

  }

  @media (max-width: 870px) {
    h2 {
      font-size: 3rem;
    }
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.3rem;
    }
  }
`;

const About = styled(motion.section)`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 5rem;
  color: white;

  &.AboutAdult {
    padding: 0; 
    min-height: 0;

    .AboutAdultDescription {
      padding-left: 10rem;
    }

  }

  &.AboutChild {
    padding: 0 ;
    margin-top: 14rem;
    align-items: center;

    .AboutChildDescription {
      padding: 0 10rem 0 5rem;
      padding-left: 5rem;
      h2 {
        text-align: left;
      }
    }
  }

  &.AboutEnterprise {
    padding-top: 0;
    margin-top: 14rem;
    min-height: auto;

    .AboutEnterpriseDescription {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 5rem;
      padding-left: 10rem;
    }
  }
  
  @media (max-width: 1300px) {
    display: block;
    padding: 2rem 2rem;

    &.AboutAdult {
      .AboutAdultDescription {
      padding-left: 0rem;
      margin-bottom: 4rem;
      }
    }

    &.AboutChild {
      display: flex;
      flex-direction: column-reverse;

      .AboutChildDescription {
        padding-left: 0rem;
        padding-right: 0;
          h2 {
            text-align: center;
          }

          ul {
            margin-bottom: 4rem;
            grid-template-columns: 1fr 1fr;
          }
      }
    }

    &.AboutEnterprise {
      
      .AboutEnterpriseDescription {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 950px) {
    .arrowDownAnimated {
      display: none;
    }
  }

  @media (max-width: 870px) {
    &.AboutChild {
      
      ul p {
        text-align: left;
      }
      
    }
  }

  @media (max-width: 520px) {

    &.AboutChild {
      margin-top: 7rem;
      .AboutChildDescription {
          ul {
            
            grid-template-columns:  1fr;
          }
      }
    }

    &.AboutEnterprise {
      margin-top: 7rem;
    }
  }
`;

const Description = styled(motion.div)`
  flex: 1;
  padding-right: 5rem;
  z-index: 2;
    .line {
      height: 0.2rem;
      background: #fea654;
      margin-bottom: 3rem;
    }

    .public-description__center {
      @media (max-width: 1300px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .public-children-description__center {
      @media (max-width: 1300px) {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .public-description__heading {
      padding: 2rem 0;
      @media (max-width: 1300px) {
        text-align: left;
      }
    }

    img {
      width: 100%;
      height: 70vh;
      object-fit: cover;
      
    }

    h2 {
      padding-top: 0;
    } 
  

  @media (max-width: 1300px) {
    padding: 0;
    button {
      margin: 2rem 0rem 5rem 0rem;
    }
  }
`;

export const Image = styled(motion.div)`
  z-index: 2;
  flex: 1;
  overflow: hidden;
  img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    border-radius: 5px;
  }

  img.schoolLogo {
    object-fit: contain;
    height: auto;
  }

  @media (max-width: 1300px) {
    max-height: 350px;
    text-align: center;

    img {
      width: 50%;
      height: auto;
    }

    .imageChild {
      object-fit: cover;
      height: 100%;
      object-position: center;
    }
  }
`;

const Formation = styled(motion.ul)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
  justify-items: flex-start;

  @media (max-width: 870px) {
  grid-template-columns: 1fr 1fr;

  }

  @media (max-width: 520px) {
      grid-template-columns: 1fr;

  }
`

const FormationListing = styled(motion.li)`
  padding: 0.3rem 0rem ;
  list-style: none;
  p {
    padding: 0
  }
`

const Hide = styled.div`
  overflow: hidden;
`;

export default AboutMe;
